import { SearchOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";

const Info = styled.div`
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    cursor: pointer;
`;

const Container = styled.div`
    flex: 1;
    padding: 25px 0rem;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: relative;
    margin: 1rem;
    font-size: 1.2rem;

    &:hover ${Info} {
        opacity: 1;
    }
    ${mobile({ minWidth: "0", width: "100%", margin: "0", fontSize: "1rem" })}
    ${tablet({ minWidth: "0", width: "100%", margin: "0" })}
`;

const HotLabel = styled.div`
    background: red;
    color: white;
    padding: 5px 10px;
    left: 0;
    top: 0;
    position: absolute;
`;

const LiveLabel = styled.div`
    background: yellow;
    color: black;
    padding: 5px 10px;
    left: 0;
    top: 0;
    position: absolute;
`;

const NewLabel = styled.div`
    background: green;
    color: white;
    padding: 5px 10px;
    left: 0;
    top: 0;
    position: absolute;
`;

const SoldoutLabel = styled.div`
    background: black;
    color: white;
    padding: 5px 10px;
    left: 0;
    top: 0;
    position: absolute;
`;

const Title = styled.div`
    font-family: "FuturaPT-Bold";
    font-weight: 600;
    color: #1f2f4d;
    text-align: center;
    max-width: 25ch;
    padding-top: 0.5rem;
    ${mobile({
        padding: "0rem 1rem 0.5rem 1rem",
        fontSize: "1rem",
    })}
    ${tablet({
        padding: "0rem 1rem 0.5rem 1rem",
        fontSize: "1.2rem",
    })}
`;

const Price = styled.div`
    font-weight: 600;
    color: black;
    padding: 0.5rem 0;
    text-align: center;
    ${mobile({
        fontSize: "0.8rem",
    })}
    ${tablet({
        fontSize: "0.8rem",
    })}
`;
// const CutPrice = styled.div`
//     font-weight: 600;
//     color: black;
//     padding: 0.5rem 0;
//     text-align: center;
//     ${mobile({
//         fontSize: "0.8rem",
//     })}
//     ${tablet({
//         fontSize: "0.8rem",
//     })}

//     // Add CSS for line-through effect
//     position: relative;
//     &::before {
//         content: "";
//         position: absolute;
//         top: 50%;
//         left: 0;
//         width: 100%;
//         height: 1px;
//         background-color: black; // Change color as needed
//         transform: rotate(-5deg); // Adjust angle of the line as needed
//     }
// `;

const Image = styled.img`
    height: 30vh;
    padding-bottom: 1rem;
    z-index: 2;
    ${mobile({
        height: "22vh",
    })}
    ${tablet({
        height: "35vh",
    })}
`;

const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    transition: all 0.5s ease;
    &:hover {
        background-color: #e9f5f5;
        transform: scale(1.1);
    }
`;

const AlternateProduct = ({ item }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const intl = useIntl();

    let imageList = [];

    for (const images of item.color) {
        imageList.push(images.img);
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndex === imageList.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, Math.floor(Math.random() * (8000 - 6000 + 1) + 1000));

        return () => clearInterval(intervalId);
    }, [currentIndex, imageList.length]);

    return (
        <Container
            style={
                window.location.pathname === "/" && window.innerWidth < 480
                    ? { display: "none" }
                    : { display: "flex" }
            }
        >
            {item.label === "new" && <NewLabel>NEW</NewLabel>}
            {item.label === "hot" && <HotLabel>HOT</HotLabel>}
            {item.label === "live" && <LiveLabel>LIVE</LiveLabel>}
            {item.label === "soldout" && <SoldoutLabel>LIMITED</SoldoutLabel>}
            <Image src={imageList[currentIndex]} />
            <Link
                style={{ textDecoration: "none", color: "black" }}
                to={`/product/${item.model}`}
            >
                <Info>
                    <Icon>
                        <SearchOutlined />
                    </Icon>
                </Info>
            </Link>
            <Title>
            {intl.locale === "es" ? item.cnTitle : item.title}
            </Title>
            {item.label === "soldout" ? (
    ``
) : (
    <>
 <Price>
                RM {item.price.toLocaleString("en-US")}.00
            </Price>
    </>
)}

        </Container>
    );
};

export default AlternateProduct;
