import styled from "styled-components";
import ReactPlayer from "react-player";
import { mobile } from "../responsive";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: black;
  padding-top: 10px;

  ${mobile({
    paddingTop: "0",
    height: "auto",
  })}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  z-index: 10;
  margin-bottom: 20px;

  ${mobile({
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    width: "100%",
    padding: "10px",
  })}
`;

const Button = styled.button`
  background-color: ${(props) => (props.active ? "yellow" : "#333")};
  color: ${(props) => (props.active ? "black" : "#fff")};
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: 120px;
  font-family: FuturaPT-Demi;

  &:hover {
    background-color: yellow;
    color: black;
  }

  ${mobile({
    width: "auto",
    fontSize: "14px",
    padding: "8px",
  })}
`;

const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 57%; /* Aspect ratio for video */

  ${mobile({
    paddingTop: "56.25%", /* Standard 16:9 ratio for mobile */
  })}
`;

const Video = () => {
  const intl = useIntl()
  const [videoUrl, setVideoUrl] = useState(
  intl.locale === "es" ? "https://youtu.be/lZIGA_hYRMQ" : "https://youtu.be/VLBcKdsY5Wk"
  );

  // Array of video data
  const videos = [
    {
      label: <FormattedMessage id="demo_title_1" defaultMessage="" />,
      chineseUrl: "https://youtu.be/lZIGA_hYRMQ",
      url: "https://youtu.be/VLBcKdsY5Wk",
    },
    {
      label: <FormattedMessage id="demo_title_2" defaultMessage="" />,
      chineseUrl: "https://youtu.be/7wGItg2vRWk",
      url: "https://youtu.be/z0uIRXHjJlE",
    },
    {
      label: <FormattedMessage id="demo_title_3" defaultMessage="" />,
      url: "https://youtu.be/wxnISitVyKQ",
      chineseUrl: "https://youtu.be/RX9Baz2dD2k",
    },
    {
      label: <FormattedMessage id="demo_title_4" defaultMessage="" />,
      url: "https://youtu.be/_vaEpNbPzbg",
      chineseUrl: "https://youtu.be/AsxdhK4Z7Ww",
    },
    {
      label: <FormattedMessage id="demo_title_5" defaultMessage="" />,
      url: "https://youtu.be/GS_oDwibyZA",
      chineseUrl: "https://youtu.be/1vuFmT0-uHI",
    },
    {
      label: <FormattedMessage id="demo_title_6" defaultMessage="" />,
      url: "https://youtu.be/OQsMxuBl9IY",
      chineseUrl: "https://youtu.be/Aor_8WQMuIY",
    },
    {
      label: <FormattedMessage id="demo_title_7" defaultMessage="" />,
      url: "https://youtu.be/jKDrW7KuOhM",
      chineseUrl: "https://youtu.be/nYmndJUzLXQ",
    },
  ];

  return (
    <Container>
      {/* Buttons Section */}
      <ButtonContainer>
        {videos.map((video) => (
          <Button
            key={video.url}
            active={videoUrl === video.url}
            onClick={() => setVideoUrl(intl.locale === "es" ? video.chineseUrl : video.url)}
          >
            {video.label}
          </Button>
        ))}
      </ButtonContainer>

      {/* Video Section */}
      <VideoContainer>
        <ReactPlayer
          url={videoUrl}
          height="100%"
          width="100%"
          controls={false}
          playing={true}
          muted={false}
          loop={true}
          className="react-player"
        />
      </VideoContainer>
    </Container>
  );
};

export default Video;
