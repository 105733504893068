import React from "react";
import { IntlProvider } from "react-intl";

// Import your translation files
import enMessages from "../lang/en.json";  // Example English messages
import esMessages from "../lang/es.json";  // Example Spanish messages

const messages = {
  en: enMessages,
  es: esMessages,
  // Add other languages as needed
};

const LocalizationWrapper = ({ children, locale }) => {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
};

export default LocalizationWrapper;
