import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { FormattedMessage } from "react-intl";

const Container = styled.div`
    padding-top: 4rem; /* Increased padding to create more space */
    padding-bottom: 2rem;
    background: #f5fafd;
    display: grid;
    align-items: center;
    flex-direction: row;

    ${mobile({
        padding: "4rem 0",
        display: "flex",
        flexDirection: "column",
    })}
    ${tablet({
        padding: "6rem 0" /* Increased padding on top for tablet version */,
        display: "flex",
        flexDirection: "column",
    })}
`;

const AboutContainer = styled.div`
    display: grid;
    justify-content: center;
    ${mobile({ display: "block" })}
    ${tablet({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.6rem;
    font-weight: 300;
    margin-top: 0rem;
    line-height: 1.5;
    max-width: 50vw;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
    ${tablet({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${mobile({ height: "100%", paddingTop: "2rem", paddingBottom: "2rem" })}
    ${tablet({ height: "100%", paddingTop: "6rem" })}
`;

const About = () => {
    const isMobile = window.innerWidth <= 768;
    return (
        <>
            <Container>
                <LeftA>
                    <AboutContainer>
                        <Desc>
                        <FormattedMessage id="about_1" defaultMessage="" />
                        </Desc>
                        <Desc>
                        <FormattedMessage id="about_2" defaultMessage="" />
                        </Desc>
                         
                    </AboutContainer>
                </LeftA>
            </Container>
        </>
    );
};

export default About;
