import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Category from "../components/Category";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";


const Hero = styled.h1`
    padding: 20px;
    background-image: url("https://ik.imagekit.io/dnddecpho/airvitamin/Air%20Vitamin_-dMqEE_hn%20(1)_wd19mq9a6.jpg?updatedAt=1732809843765");
    background-position: center;
    background-size: cover; /* Ensures the entire image is visible */
    height: 60vh; /* Set the desired height */
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;

    ${mobile({
        height: "40vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${"https://ik.imagekit.io/dnddecpho/airvitamin/Air%20Vitamin_-dMqEE_hn%20(1)_wd19mq9a6.jpg?updatedAt=1732809843765"})`,
    })}

    ${tablet({
        height: "40vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${"https://ik.imagekit.io/dnddecpho/airvitamin/Air%20Vitamin_-dMqEE_hn%20(1)_wd19mq9a6.jpg?updatedAt=1732809843765"})`,
    })}
`;


// Note that Swiper component is for mobile only while Product component is for desktop only, both replacing the other

const Home = () => {
    return (
        <div>
            <Hero></Hero>
            <Category />
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Home;
