
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Background from "../components/Background";

// Note that Swiper component is for mobile only while Product component is for desktop only, both replacing the other

const AboutUs = () => {
    return (
        <div>
            <Background />
            <Newsletter />
            <Footer />
        </div>
    );
};

export default AboutUs;
