import styled from "styled-components";
import { mobile } from "../responsive";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Announcement from "../components/Announcement";
import { useEffect } from "react";

const Container = styled.div`
    display: flex;
    margin-bottom: 3rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: black;
    ${mobile({ margin:"0" })}
`;
const Title = styled.h1`
    text-transform: uppercase;
    padding: 1rem;
    font-family: FuturaPT-Demi;
    font-size: 40px;
    margin: 50px 0;
    background: black;
    color: white;
    ${mobile({ margin: "1rem", textAlign: "center", fontSize: "2rem" })}
`;

const AboutContainer = styled.div`
    padding: 3rem 5rem;
    display: grid;
    background: white;
    ${mobile({ padding: "1rem 1.5rem", maxWidth: "100vh" })}
`;

const Heading = styled.h2`
    padding: 1.5rem 3rem;
    font-size: 22px;
    font-weight: 300;
    ${mobile({ textAlign: "left", padding: "0rem" })}
`;

const Desc = styled.div`
    padding: 0 3rem;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 2rem;
    line-height: 1.5;
    ${mobile({ textAlign: "left", margin: "0rem 0rem", padding: "1rem 0rem" })}
`;

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Announcement />
            <Container>
  <Title>Privacy Policy</Title>
  <AboutContainer>
    <Heading>COLLECTING PERSONAL INFORMATION</Heading>
    <Desc>
      When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.

      **Device information**
      Examples of Personal Information collected: version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with the Site.
      Purpose of collection: to load the Site accurately for you, and to perform analytics on Site usage to optimize our Site.
      Source of collection: Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels, or other tracking technologies.
      Disclosure for a business purpose: shared with our processor Shopify and other vendors we use to provide services.

      **Order information**
      Examples of Personal Information collected: name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.
      Purpose of collection: to provide products or services to you to fulfill our contract, process your payment information, arrange for shipping, provide invoices and/or order confirmations, communicate with you, screen orders for potential risk or fraud, and provide you with relevant information or advertising.
      Source of collection: collected from you.
      Disclosure for a business purpose: shared with our processor Shopify, sales channels, payment gateways, and shipping and fulfillment apps.

      **Customer support information**
      Examples of Personal Information collected: details relevant to your support inquiry.
      Purpose of collection: to provide customer support.
      Source of collection: collected from you.
      Disclosure for a business purpose: shared with vendors who provide customer support services.
    </Desc>

    <Heading>SHARING PERSONAL INFORMATION</Heading>
    <Desc>
      We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. For example:
      - We use Shopify to power our online store. You can read more about how Shopify uses your Personal Information here: https://www.shopify.com/legal/privacy.
      - We may share your Personal Information to comply with applicable laws and regulations, to respond to subpoenas, search warrants, or other lawful requests for information, or to otherwise protect our rights.
    </Desc>

    <Heading>BEHAVIORAL ADVERTISING</Heading>
    <Desc>
      As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications that we believe may be of interest to you. For example:
      - We use Google Analytics to help us understand how our customers use the Site. You can read more about how Google uses your Personal Information here: https://policies.google.com/privacy?hl=en. You can also opt out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
      - We may share information about your use of the Site, your purchases, and your interactions with ads on other websites with our advertising partners, either directly or through the use of cookies or similar technologies.
      For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
      You can opt out of targeted advertising by visiting the following links:
      - Facebook: https://www.facebook.com/settings/?tab=ads
      - Google: https://www.google.com/settings/ads/anonymous
      - Bing: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
      Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at http://optout.aboutads.info/.
    </Desc>

    <Heading>USING PERSONAL INFORMATION</Heading>
    <Desc>
      We use your Personal Information to provide our services to you, which includes: offering products for sale, processing payments, shipping and fulfillment of your order, and keeping you up to date on new products, services, and offers.
    </Desc>
  </AboutContainer>
</Container>

            <Newsletter />
            <Footer />
        </>
    );
};

export default Privacy;
