import {
    Add,
    Remove,
    ArrowLeftOutlined,
    ArrowRightOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import { mobile, tablet } from "../responsive";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { publicRequest } from "../requestMethods";
import { addProduct, addQuantity } from "../redux/cartRedux";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";

const Container = styled.div`
    font-size: 1.2rem;
    line-height: 2.5rem;
    ${mobile({
        fontSize: "1rem",
    })}
    ${tablet({
        fontSize: "1rem",
    })}
`;

const Wrapper = styled.div`
    padding: 10px 50px;
    display: flex;
    ${mobile({ padding: "10px", flexDirection: "column" })}
    ${tablet({ padding: "10px", flexDirection: "column" })}
`;

const ImgContainer = styled.div`
    flex: 1;
    ${mobile({ order: "1" })}
    ${tablet({ order: "1" })}
`;

const Image = styled.img`
    width: 100%;
    height: 60vh;
    object-fit: contain;
    cursor: pointer;
    ${mobile({ height: "30vh", margin: "0" })}
    ${tablet({ height: "50vh", margin: "0" })}
`;

const FullImage = styled.img`
    width: 75vh;
    height: 100vh;
    object-fit: contain;
    cursor: pointer;
    background: white;
    ${mobile({ height: "30vh", margin: "0" })}
    ${tablet({ height: "30vh", margin: "0" })}
`;

const GalleryFullImage = styled.img`
    width: 100vh;
    height: 100vh;
    object-fit: contain;
    cursor: pointer;
    ${mobile({ height: "30vh", margin: "0" })}
    ${tablet({ height: "30vh", margin: "0" })}
`;

const InfoContainer = styled.div`
    flex: 1;
    ${mobile({ padding: "0rem 10px", order: "3" })}
    ${tablet({ padding: "0rem 10px", order: "3" })}
`;

const Title = styled.h1`
    font-family: FuturaPT-Bold;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 800;
    padding-bottom: 10px;
    overflow-wrap: anywhere;
    ${mobile({ fontSize: "1.5rem", margin: "0" })}
    ${tablet({ fontSize: "1.5rem", margin: "0" })}
`;


const Subtitle = styled.p`
    font-family: FuturaPT-Demi;
    font-size: 1.4rem;
    ${mobile({ padding: "0", lineHeight: "2.5rem", fontSize: "1rem" })}
    ${tablet({ padding: "0", lineHeight: "2.5rem", fontSize: "1rem" })}
`;

const Desc = styled.p`
    font-family: FuturaPT-Book;
    font-weight: 400;
    ${mobile({ padding: "0", lineHeight: "2.5rem", fontSize: "1rem" })}
    ${tablet({ padding: "0", lineHeight: "2.5rem", fontSize: "1rem" })}
`;

const WatchContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    ${mobile({ marginBottom: "0" })}
    ${tablet({ marginBottom: "0" })}
`;

const Price = styled.div`
    font-family: FuturaPT-Book;
    font-size: 20px;
    ${mobile({ fontSize: "1rem" })}
    ${tablet({ fontSize: "1rem" })}
`;

const AddContainer = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mobile({ width: "100%", margin: "0" })}
    ${tablet({ width: "100%", margin: "0" })}
`;

const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: 700;
`;

const Amount = styled.span`
    width: 30px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid teal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
`;

const Button = styled.button`
    width: 80%;
    margin: 25px 0;
    padding: 15px;
    border: 2px solid #1f2f4d;
    background-color: black;
    color: white;
    cursor: pointer;
    font-family: FuturaPT-Bold;

    &:hover {
        background-color: #ac997b;
        border: 2px solid transparent;
    }
    ${mobile({ width: "100%", margin: "1rem 0" })}
    ${tablet({ width: "100%", margin: "1rem 0" })}
`;

const ImageModalContainer = styled.div`
    height: 100%;
    width: 100%;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
`;


const ImageOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: white;
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
    width: 33.33%;
    display: flex;
    z-index: 102;
    ${mobile({ width: "100%", height: "100%" })}
    ${tablet({ width: "100%", height: "100%" })}
`;


const Close = styled.div`
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 2rem;
`;


const Arrow = styled.div`
    width: 50px;
    height: 50px;
    background-color: #fff7f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props) => props.direction === "left" && "10px"};
    right: ${(props) => props.direction === "right" && "10px"};
    margin: auto;
    cursor: pointer;
    opacity: 0.5;
    z-index: 9999;
`;

const GalleryModalContainer = styled.div`
    height: 100%;
    width: 100%;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
`;

const Product = () => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [imageModal, setImageModal] = useState(false);
    const [galleryModal, setGalleryModal] = useState(false);
    const [product, setProduct] = useState({});
    const [quantity, setQuantity] = useState(1);
    //eslint-disable-next-line
    const [colorpicker, setColorPicker] = useState(false);
    const [activeColor, setActiveColor] = useState({});
    const dispatch = useDispatch();
     const intl = useIntl()

    const admin = useSelector((state) => state.cart.products);

    useEffect(() => {
        window.scrollTo(0, 0);
        const getProduct = async () => {
            try {
                const res = await publicRequest.get("/products/find/" + id);
                setProduct(res.data);
                setActiveColor({ color: res.data.color[0], i: 0 });
            } catch {}
        };
        getProduct();
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (colorpicker === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [colorpicker]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (imageModal === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [imageModal]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (galleryModal === false) {
            document.body.style.overflow = "inherit";
        } else {
            document.body.style.overflow = "hidden";
        }
    }, [galleryModal]);

    const handleQuantity = (type) => {
        if (type === "dec") {
            quantity > 1 && setQuantity(quantity - 1);
        } else {
            setQuantity(quantity + 1);
        }
    };

    const handleClick = () => {
        try {
            if (
                admin.find(
                    (item) =>
                        `${item.model}-${item.color.name}` ===
                        `${product.model}-${activeColor.name}`
                ) === undefined
            ) {
                dispatch(addProduct({ ...product, activeColor, quantity }));
            } else {
                dispatch(addQuantity({ ...product, activeColor, quantity }));
            }
            alert("Product added to cart");
            window.scrollTo(0, 0);
        } catch {}
    };

    const handleGallerySwipe = (direction) => {
        const totalGallery = product.gallery.length;
        if (direction === "right") {
            const newIndex =
                galleryModal.i + 1 < totalGallery ? galleryModal.i + 1 : 0;
            setGalleryModal({
                gallery: product.gallery[newIndex],
                i: newIndex,
            });
        } else {
            const newIndex =
                galleryModal.i - 1 < 0 ? totalGallery - 1 : galleryModal.i - 1;
            setGalleryModal({
                gallery: product.gallery[newIndex],
                i: newIndex,
            });
        }
    };

    const handleImageSwipe = (direction) => {
        const totalColor = product.color.length;
        if (direction === "right") {
            const newIndex =
                activeColor.i + 1 < totalColor ? activeColor.i + 1 : 0;
            setActiveColor({
                color: product.color[newIndex],
                i: newIndex,
            });
        } else {
            const newIndex =
                activeColor.i - 1 < 0 ? totalColor - 1 : activeColor.i - 1;
            setActiveColor({
                color: product.color[newIndex],
                i: newIndex,
            });
        }
    };
    return (
        <Container>
            <Announcement />
            <>
                {" "}
                <Wrapper>
                    <ImgContainer>
                        <Image
                            onClick={() => {
                                window.innerWidth > 480
                                    ? setImageModal(true)
                                    : setImageModal(false);
                            }}
                            src={
                                activeColor.color
                                    ? activeColor.color.img
                                        ? activeColor.color.img
                                        : ""
                                    : ""
                            }
                        />
                    </ImgContainer>
                    <InfoContainer>
                        <WatchContainer>
                        {product.title === "G10" ? (
    <>
        <Title><FormattedMessage id="product_title_1" defaultMessage="" /></Title>
        <Title><FormattedMessage id="product_title_2" defaultMessage="" />!</Title>
    </>
) : (
    <Title>{intl.locale === "es" ? product.cnTitle : product.title}</Title>
)}

                            <Subtitle><FormattedMessage id="product_subtitle_1" defaultMessage="" /> </Subtitle>
                            <Desc dangerouslySetInnerHTML={{ __html: intl.locale === "es" ? `${product.cnDescription ? product.cnDescription.replace(/\n/g, "<br>") : ""}` : `${product.description ? product.description.replace(/\n/g, "<br>"): ""}` }} />
                            <Desc>
                                {" "}
                                {product.powerReserve
                                    ? `Power Reserve : ${product.powerReserve}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.vibrationFrequency
                                    ? `Vibration Frequency : ${product.vibrationFrequency}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.drive
                                    ? `Drive : ${product.drive}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.windingMethod
                                    ? `Winding Method : ${product.windingMethod}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.caseDiameter
                                    ? `Case Diameter : ${product.caseDiameter}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.caseMaterial
                                    ? `Case Material : ${product.caseMaterial}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.waterResistance
                                    ? `Water Resistance : ${product.waterResistance}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.mirror
                                    ? `Mirror : ${product.mirror}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.strapMaterial
                                    ? `Strap Material : ${product.strapMaterial}`
                                    : ""}
                            </Desc>
                        </WatchContainer>
                        <AddContainer>
                        {product.label === "soldout" ? (
    ``
) : (
    <>
              <Price>
                RM {(product.price)}.00
            </Price>
    </>
)}

                            <AmountContainer>
                                <Remove onClick={() => handleQuantity("dec")} />
                                <Amount>{quantity}</Amount>
                                <Add onClick={() => handleQuantity("inc")} />
                            </AmountContainer>
                        </AddContainer>
                       {
                        product.label === "soldout" ?  <Button>LIMITED</Button> :  <Button onClick={handleClick}><FormattedMessage id="product_button" defaultMessage="" /></Button>
                       }
                    </InfoContainer>
                </Wrapper>
            </>
            {imageModal && (
                <>
                    <Arrow
                        direction="left"
                        onClick={() => handleImageSwipe("left")}
                    >
                        <ArrowLeftOutlined />
                    </Arrow>
                    <ImageModalContainer
                        onClick={() => setImageModal(false)}
                    ></ImageModalContainer>
                    <ImageOverlay>
                        <ModalDetails>
                            <Close onClick={() => setImageModal(false)}>
                                X
                            </Close>
                            <FullImage
                                onClick={() => setImageModal(true)}
                                src={
                                    activeColor.color.img
                                        ? activeColor.color.img
                                        : ""
                                }
                            />
                        </ModalDetails>
                    </ImageOverlay>
                    <Arrow
                        direction="right"
                        onClick={() => handleImageSwipe("right")}
                    >
                        <ArrowRightOutlined />
                    </Arrow>
                </>
            )}

            {galleryModal && (
                <>
                    <Arrow
                        direction="left"
                        onClick={() => handleGallerySwipe("left")}
                    >
                        <ArrowLeftOutlined />
                    </Arrow>
                    <GalleryModalContainer
                        onClick={() => setGalleryModal(false)}
                    ></GalleryModalContainer>
                    <ImageOverlay>
                        <ModalDetails>
                            <Close onClick={() => setGalleryModal(false)}>
                                X
                            </Close>
                            <GalleryFullImage
                                onClick={() => setGalleryModal(galleryModal)}
                                src={
                                    galleryModal
                                        ? product.gallery[galleryModal.i].img
                                        : ""
                                }
                            />
                        </ModalDetails>
                    </ImageOverlay>
                    <Arrow
                        direction="right"
                        onClick={() => handleGallerySwipe("right")}
                    >
                        <ArrowRightOutlined />
                    </Arrow>
                </>
            )}
            <Newsletter />
            <Footer />
        </Container>
    );
};

export default Product;
