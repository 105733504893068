import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Video from "../components/Video";

// Note that Swiper component is for mobile only while Product component is for desktop only, both replacing the other

const Home = () => {
    return (
        <div>
            <Video/>
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Home;
